// collection if commonly used icons
// TODO: maybe add all icons
export const icons = {
  add: 'fa-solid:plus',
  delete: 'fa6-solid:trash-can',
  user: 'fa-solid:user',
  external: 'fa6-solid:at',
  companyAccount: 'fa6-solid:building-circle-exclamation',
  search: 'fa-solid:search',
  close: 'fa6-solid:xmark',
  settings: 'fa6-solid:gear',
  save: 'fa6-regular:file-lines',
  scheduled: 'fa6-solid:stopwatch',
  reminder: 'fa6-solid:bell',
  relates: 'fa6-solid:link',
  xls: 'ph:file-xls'
};
