import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Loader } from 'src/components/loader';
import { Layout } from 'src/layouts/default';

// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const FeedPage = lazy(() => import('src/pages/user/feed'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const PostPage = lazy(() => import('src/pages/user/post'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const ResultsPage = lazy(() => import('src/pages/user/post/results'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const RecipientPage = lazy(() => import('src/pages/user/post/recipient'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const AttendeePage = lazy(() => import('src/pages/user/post/attendee'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const DocumentsPage = lazy(() => import('src/pages/user/documents'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const CalendarPage = lazy(() => import('src/pages/user/calendar'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const ChatPage = lazy(() => import('src/pages/user/chat'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const UserMemberPage = lazy(() => import('src/pages/user/account'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const UserCompanyPage = lazy(() => import('src/pages/user/company'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const AdminCompanyPage = lazy(() => import('src/pages/admin/company'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const AdminMemberPage = lazy(() => import('src/pages/admin/company/member'));

export const userRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <Layout direction="horizontal">
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Layout>
    ),
    children: [
      {
        index: true,
        element: <FeedPage />
      },
      {
        path: 'post/:id',
        element: <PostPage />
      },
      {
        path: 'results/:id',
        children: [
          {
            index: true,
            element: <ResultsPage />
          },
          {
            path: 'recipient/:memberId',
            element: <RecipientPage />
          },
          {
            path: 'attendee/:memberId',
            element: <AttendeePage />
          }
        ]
      },
      {
        path: 'documents',
        children: [
          {
            index: true,
            element: <DocumentsPage />
          },
          {
            path: ':id',
            element: <DocumentsPage />
          }
        ]
      },
      {
        path: 'calendar',
        element: <CalendarPage />
      },
      {
        path: 'chat',
        children: [
          {
            index: true,
            element: <ChatPage />
          },
          {
            path: ':channel',
            element: <ChatPage />
          }
        ]
      },
      {
        path: 'account',
        element: <UserMemberPage />
      },
      {
        path: 'company',
        children: [
          {
            index: true,
            element: <UserCompanyPage />
          },
          {
            path: 'edit',
            element: <AdminCompanyPage />
          },
          {
            path: 'member/:id',
            element: <AdminMemberPage />
          }
        ]
      }
    ]
  }
];
