import { useEffect, useMemo } from 'react';
import { useGetApiUsersMeQuery } from 'src/api/deinverband-api';
import { mapUserDetailsToProfile } from 'src/mappings/user-details-to-profile';
import { Profile } from 'src/types/user';
import { useAuth } from './use-auth';
import * as Sentry from "@sentry/browser";

export const useMe = (): Profile | undefined => {
  const { user } = useAuth();
  const { data, isSuccess } = useGetApiUsersMeQuery();

  useEffect(() => {
    if (!isSuccess) return;
    if (!data) return;
    if (!user) return;
    Sentry.setUser({
      email: data.email || undefined,
      id: user.uid || undefined,
      username: [data.firstName ?? '', data.lastName ?? ''].join(' ')
    });
  }, [data, user, isSuccess]);

  return useMemo((): Profile | undefined => {
    // populate profile with auth data
    const profile = mapUserDetailsToProfile(data);

    return (
      profile && {
        ...profile,
        avatar: user?.avatar,
        uid: user?.uid
      }
    );
  }, [data, user]);
};
